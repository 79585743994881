<template>
  <div>
    <div>
      {{tableCellsConfig[order].name}}
      <el-popover
        v-if="tableCellsConfig[order].desc"
        placement="top"
        width="400"
        trigger="click"
      >
        <el-button
          slot="reference"
          class="table-action-button open-desc-button"
          type="text"
          round
          size="mini"
          icon="fas fa-info-circle"
        ></el-button>
        <div v-html="tableCellsConfig[order].desc"></div>
      </el-popover>
    </div>
    <div>({{cardsByCells[order].length}}/{{totalCardsInTableCount}})</div>


  </div>
</template>

<script>

import {downloader} from "@/mixins/downloader";


export default {
  name: "HumanResourcesMatrixTableIntersectionName",
  mixins: [downloader],
  props: {
    order: {type: String, required: true},
    tableCellsConfig: {type: Object, required: true},
    cardsByCells: {type: Object, required: true},
    totalCardsInTableCount: {type: Number, required: true},
  },


  data() {
    return {

    }
  },

  methods: {

  },
}
</script>
